<template>
    <v-card tile :height="height">
        <l-map ref="map" :zoom="zoom" :center="center" :options="{ preferCanvas: true }" style="z-index: 1 !important;">
            <v-tilelayer-googlemutant lang="es-CL" :options="{ type: 'hybrid' }" />
            <!--v-tilelayer-googlemutant apikey="YOUR_API_KEY" lang="es-CL" :options="{ type: 'hybrid' }" /-->
        </l-map>
    </v-card>
</template>

<script>
import * as turf from '@turf/turf';
import { LMap, LTileLayer } from 'vue2-leaflet'
import { KMZParser }  from 'leaflet-kmz'
import LDraw from 'leaflet-draw';
import 'leaflet/dist/leaflet.css';
import 'leaflet-draw/dist/leaflet.draw.css';

import 'leaflet-fullscreen/dist/leaflet.fullscreen.css';
import 'leaflet-fullscreen/dist/Leaflet.fullscreen';

import Vue2LeafletGoogleMutant from 'vue2-leaflet-googlemutant';

export default {
    props: ['height', 'editable'],
    components:{
        LMap,
        LTileLayer,
        'v-tilelayer-googlemutant': Vue2LeafletGoogleMutant
    },
    data: () => ({
        layer_control: window.L.control.layers(null, null, { collapsed: true }),
        kmz_parser: null,
        kmz_layers: [],
        kmz_raws: [],
        kmz_list: [],
        result: {},
        zoom:18,
        center: L.latLng(-31.8226285,-70.5819457),
        edit_layer: null
    }),
    methods: {
        set_center(lat, lng) {
            this.center = L.latLng(lat, lng);
        },
        clear_edit_layer() {
            if(!this.edit_layer) return;
            this.edit_layer.clearLayers();
        },
        add_geojson(json) {
            const map = this.$refs.map.mapObject;
            let obj = window.L.geoJSON(json);
            console.log(obj);
            this.edit_layer.addLayer(obj);
        },
        add_layer(nombre, json) {
            const map = this.$refs.map.mapObject;
            let layer = window.L.geoJSON(json);

            this.layer_control.addOverlay(layer, nombre);
            layer.addTo(map);
            return layer;
        },
        set_poly_data(json) {
            const map = this.$refs.map.mapObject;

            var list = json.coordinates;
            console.log(list);

            for(let points of list) {  
                this.edit_layer.addLayer(window.L.polygon(points));
            }
        },
        update_json() {
            this.result = this.edit_layer.toGeoJSON();
        },
        get_result() {
            this.update_json();
            return this.result;
        },
        load_kmz_list(list) {
            this.kmz_list = list;
            this.kmz_list.forEach(kmz => this.kmz_parser.load(kmz));
        },
        collides_with_layer() {
            const target = this.get_result();

            //TODO: esto consume un porcentaje importante de CPU, revisar una solución async.
            for (let i = 0; i < this.kmz_layers.length; i++) {
                const kmz = this.kmz_layers[i];

                if(!this.kmz_raws[i]._map) continue;
                
                for (let j = 0; j < kmz.features.length; j++) {
                    const kmz_feature = kmz.features[j];
                    
                    for (let k = 0; k < target.features.length; k++) {
                        const target_feature = target.features[k];
                        
                        if(this.check_collision(kmz_feature, target_feature)) {
                            return true;
                            break;
                        }
                    }
                }
            }

            return false;
        },
        check_collision(kmz_feature, target_feature) {
            
            if(!!kmz_feature.geometry && kmz_feature.geometry.type == 'GeometryCollection') {
                const array = kmz_feature.geometry.geometries;

                for (let i = 0; i < array.length; i++) {
                    const second_feature = array[i];

                    if(this.check_collision(second_feature, target_feature)) {
                        return true;
                    }
                }
            } else {
                try {
                    let collide = turf.intersect(kmz_feature, target_feature);

                    if(collide) {
                        console.log("COLISIONA");
                        return true;
                    }
                } catch(error) {
                    return false;
                }
            }
            return false;    
        }
    },
    mounted() {
        const map = this.$refs.map.mapObject;
        map.addControl(new window.L.Control.Fullscreen());

        this.layer_control.addTo(map);

        const kmz_layers = [];
        const kmz_raws = [];
        this.edit_layer = new window.L.FeatureGroup();

        this.$nextTick(() => {
            const control = this.layer_control;

            this.kmz_parser = new KMZParser({
                onKMZLoaded: function(layer, name) {
                    if(!layer) return;
                    
                    control.addOverlay(layer, name);
                    layer.addTo(map);

                    kmz_layers.push(layer.toGeoJSON());
                    kmz_raws.push(layer);
                }
            });

            this.kmz_layers = kmz_layers;
            this.kmz_raws = kmz_raws;
            
            const drawControl = new window.L.Control.Draw({
                position: 'topleft',
                draw: {
                    polyline: false,
                    polygon: true,
                    rectangle: true,
                    circle: false,
                    circlemarker: false,
                    marker: true
                },
                edit: {
                    featureGroup: this.edit_layer,
                    poly: {
                        allowIntersection: true
                    }
                }
            });
            map.addLayer(this.edit_layer);
            if(this.editable)
                map.addControl(drawControl);

            map.on('draw:created', (e) => {
                // const type = e.layerType;
                const layer = e.layer;
                this.edit_layer.addLayer(layer);
                this.update_json();
            });

            map.on('draw:edited', (e) => {
                // const type = e.layerType;
                const layer = e.layer;
                this.update_json();
            });

            map.on('draw:deleted', (e) => {
                // const type = e.layerType;
                const layer = e.layer;
                this.edit_layer.removeLayer(layer);
                this.update_json();
            });

            this.$emit('ready', this);
        });
    }
}
</script>
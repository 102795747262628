<template>
    <DashCard title="Georeferenciación" :actions="true" actions-align="right">
        <v-row dense>
            <template v-if="readonly">
                <v-col v-if="!value" cols="12">
                    <v-alert type="warning">Este registro de incidente no tiene adjunto una georeferencia.</v-alert>
                </v-col>
            </template>
            <template v-else>
                <v-col>
                    <v-flex wrap>
                        <v-btn depressed dark large color="primary" class="ma-1 subtitle-2" @click="show_editor">
                            Crear/Editar Geometría
                        </v-btn>
                        <v-btn depressed dark large color="red" class="ma-1 subtitle-2" @click="ask_delete">
                            Eliminar geometría
                        </v-btn>
                    </v-flex>
                </v-col>
            </template>
            <v-col cols="12">
                <MapCard ref="geomapa" height="600"></MapCard>
            </v-col>
        </v-row>
        <DialogEditGeoreferencia ref="dialog_geo" @save="apply_changes" />
        <CommonDialog ref="dialog_delete" @onClose="delete_data" />
    </DashCard>
</template>


<script>
import DashCard from '@/shared/components/DashCard'
import MapCard from '@/dash/components/MapCard'
import DialogEditGeoreferencia from '@/dash/dialogs/DialogEditGeoreferencia'
import CommonDialog from '@/shared/components/CommonDialog'

export default {
    components: {
        DashCard,
        MapCard,
        DialogEditGeoreferencia,
        CommonDialog
    },
    props: {
        value: { type: Object, default: null },
        readonly: { type: Boolean, default: false }
    },
    data: () => ({
        enabled: false
    }),
    methods: {
        show_editor() {
            console.log(this.value);
            this.$refs.dialog_geo.show(this.value);
        },
        ask_delete() {
            this.$refs.dialog_delete.showDialog("Eliminar datos", "¿Está seguro/a que desea eliminar los datos?", "yes_no");
        },
        delete_data(response) {
            if (response == 'yes') {
                this.$emit('input', null);
            }
        },
        apply_changes(data) {
            if(!this.value) {
                this.$emit('input', { data: data });
            } else {
                this.value.data = data;
            }
        },
        get_result() {
            const mapa = this.$refs.geomapa;
            return mapa.get_result();
        },
        get_data() {
            if(!this.$refs.geomapa)
                return null;

            const mapa = this.$refs.geomapa;
            const geojson = mapa.get_result();

            if(geojson.features.length > 0) {
                const result =  geojson.features[0].geometry;
                return { data: result };
            }

            return null;
        }
    },
    watch: {
        value: function(_new, _old) {
            const mapa = this.$refs.geomapa;
            mapa.clear_edit_layer();

            if(!!_new) {
                mapa.add_geojson(this.value.data);
            }
        }
    }
}
</script>
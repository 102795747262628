<template>
    <v-dialog v-model="dialog.visible" max-width="800" persistent transition="fade-transition">
        <WindowCard :title="dialog.title" actions-align="right">
            <MapCard ref="mapa" :editable="true" :height="640" />
            <template slot="actions">
                <v-btn text large color="primary" @click="close">
                    <span class="subtitle-2">Descartar</span>
                </v-btn>
                <v-btn large depressed color="primary" type="submit" form="creator_form" @click="save">
                    <v-icon>done</v-icon>
                    <span class="ml-2 subtitle-2">Guardar</span>
                </v-btn>
            </template>
        </WindowCard>
    </v-dialog>    
</template>

<script>
import MapCard from '@/dash/components/MapCard'
import WindowCard from '@/shared/components/WindowCard'

export default {
    components: {
        WindowCard,
        MapCard
    },
    data: () => ({
        dialog: {
            visible: false,
            title: "Editar Georeferencia"
        }
    }),
    methods: {
        show(georeferencia) {
            this.dialog.visible = true;

            if(!!georeferencia) {
                //this.$refs.mapa.set_center(georeferencia.center[0], georeferencia.center[1]);
                this.$refs.mapa.add_geojson(georeferencia.data);
            }
        },
        save() {
            const result = this.$refs.mapa.get_result().features[0].geometry;
            this.$emit('save', result);
            this.dialog.visible = false;
        },
        close() {
            this.dialog.visible = false;
            this.$refs.mapa.clear_edit_layer();
        }
    }
}
</script>
<template>
    <v-list-item :twoLine="!!value">
        <v-list-item-icon>
            <v-icon x-large color="primary">account_circle</v-icon>
        </v-list-item-icon>
        <v-list-item-content v-if="!!value">
            <v-list-item-title>{{ value.first_name }} {{ value.last_name }}</v-list-item-title>
            <v-list-item-subtitle><b>{{ value.areas }}</b></v-list-item-subtitle>
            <v-list-item-subtitle>{{ value.cargo }}</v-list-item-subtitle>
        </v-list-item-content>
        <v-list-item-content v-else>
            <v-list-item-title>Sin datos</v-list-item-title>
        </v-list-item-content>
    </v-list-item>
</template>

<script>
export default {
    props: ['value']
}
</script>
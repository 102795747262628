<template>
    <v-dialog v-model="dialog.visible" max-width="800" persistent transition="fade-transition">
        <WindowCard :title="dialog.title" actions-align="right">
            <v-form id="form" ref="creator_form">
                <v-row dense>
                    <v-col cols="12" md="12">
                        <v-label>Tarea</v-label>
                        <v-textarea v-model="creator.item.texto" counter="500" solo dense outlined flat placeholder="Breve descripción de tarea" :rules="[rules.required]" />
                    </v-col>
                    <v-col cols="12" md="12" v-if="modelType == 'permisos'">
                        <v-label>Responsable</v-label>
                        <v-select solo dense outlined flat
                            prepend-inner-icon="account_circle" 
                            v-model="creator.item.persona_autor" 
                            :rules="[rules.required]" 
                            :items="personas" 
                            :item-text="(i) => i.first_name + ' ' + i.last_name" 
                            return-object />
                    </v-col>
                    <v-col cols="12" md="6">
                        <v-label>Estado</v-label>
                        <v-select solo dense outlined flat 
                            prepend-inner-icon="flag"
                            v-model="creator.item.estado" 
                            :rules="[rules.required]"
                            :items="estados" 
                            item-text="text"
                            :return-object="false" 
                            item-value="value" />
                    </v-col>
                    <v-col cols="12" md="6">
                        <v-label>Vencimiento</v-label>
                        <DatePickerText dense flat ref="vencimiento" v-model="creator.item.vencimiento" :rules="[rules.required]" />
                    </v-col>
                </v-row>
            </v-form>
           <template slot="actions">
                <v-btn text large color="primary" @click="close">
                    <span class="subtitle-2">Descartar</span>
                </v-btn>
                <v-btn large depressed color="primary" type="submit" form="creator_form" @click="submit">
                    <v-icon>done</v-icon>
                    <span class="ml-2 subtitle-2">{{ (creator.edit ? 'Guardar' : 'Añadir') }}</span>
                </v-btn>
            </template>
        </WindowCard>

        <Message ref="error" />
    </v-dialog>
</template>

<script>
import { messages, valid_required } from '@/helpers'
import raw_estados from '@/assets/estados'
import WindowCard from '@/shared/components/WindowCard'
import Message from '@/shared/components/Message'
import DatePickerText from '@/shared/components/DatePickerText'

export default {
    props: {
        modelType: {
            type: String,
            default: "default"
        },
        personas: {
            type: Array,
            default: () => ([])
        }
    },
    components: {
        WindowCard,
        Message,
        DatePickerText
    },
    data: () => ({
        rules: {
            required: value => valid_required(value)
        },
        dialog: {
            visible: false,
            title: ''
        },
        creator: {
            edit: false,
            item: {},
            index: -1
        }
    }),
    computed: {
        estados() {
            return raw_estados['tarea']
        }
    },
    methods: {
        show(edit, item, index) {
            if(this.$refs.creator_form)
                this.$refs.creator_form.reset();

            this.dialog.visible = true;
            this.creator.edit = edit;

            if(this.creator.edit) {
                this.dialog.title = "Editar Tarea";
                this.creator.item = { ... item };
                this.creator.index = index;
            } else {
                this.dialog.title = "Nueva Tarea";
            }
        },
        close() {
            this.dialog.visible = false;
            this.creator.item = {};

            if(this.$refs.creator_form)
                this.$refs.creator_form.reset();
        },
        submit() {
            if(!this.$refs.creator_form.validate()) {
                this.error();
                return;
            }

            let target = this.creator.item;
            if(!this.creator.edit) {
                target = {... this.creator.item };
                if(target.estado == 1) {
                    target.cierre = null;
                    target.persona_cierre = null;
                }
            }
            
            this.$emit('success', target, this.creator.edit, this.creator.index);
            this.close();
        },
        error(data) {
            let text = messages.texts["error_check_fields"];

            if(data) {
                text = data;
            }
            this.$refs.error.show(messages.titles["error_generico"], text, "error");
        },
    }
}
</script>
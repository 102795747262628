<template>
    <div>
        <v-row v-if="!$parent.$parent.get_permission('read')">
            <v-col>
                <AccessMessage />
            </v-col>
        </v-row>
        <v-row v-else>
            <!-- menu superior -->
            <v-col cols="12">
                <v-btn v-if="$parent.$parent.get_permission('edit')" class="ma-1" large solo depressed color="secondary" :to="{ name: 'dashboard_incidentes_edit', params: $router.params }">
                    <v-icon class="mr-2">edit</v-icon>
                    <span class="subtitle-2">Editar</span>
                </v-btn>
            </v-col>
            <!-- general -->
            <v-col cols="12" sm="6" md="6" lg="4">
                <DashCard title="General">
                    <v-row>
                        <v-col cols="12">
                            <v-label>Código ID</v-label>
                            <v-text-field hide-details dense solo outlined readonly flat v-model="item.cod_incidente" />
                        </v-col>
                        <v-col cols="12">
                            <v-label>Fecha de registro</v-label>
                            <v-text-field hide-details dense solo outlined readonly flat :value="item.fecha_incidente | moment('L')" prepend-inner-icon="event" />
                        </v-col>
                        <v-col cols="12">
                            <v-label class="d-block">Estado</v-label>
                            <v-flex>
                                <v-chip class="ma-1" dark large label :color="item.estado | color_estado('incidente')">
                                    <v-icon class="pr-2">flag</v-icon>
                                    <span>{{ item.estado | estado('incidente') }}</span>
                                </v-chip>
                                <v-chip class="ma-1" dark large label :color="item.estado_tiempo | color_estado('tiempo')">
                                    <v-icon class="pr-2">av_timer</v-icon>
                                    <span>{{ item.estado_tiempo | estado('tiempo') }}</span>
                                </v-chip>
                            </v-flex>
                        </v-col>
                    </v-row>
                </DashCard>
            </v-col>
            <v-col cols="12" sm="6" md="6" lg="8">
                <DashCard title="Detalles">
                    <v-row>
                        <v-col cols="12" md="12" lg="10">
                            <v-label>Titulo</v-label>
                            <v-text-field v-model="item.titulo" hide-details dense solo outlined readonly flat />
                        </v-col>
                        <v-col cols="12" md="4" lg="2">
                            <v-label>Clasif.</v-label>
                            <v-text-field v-model="item.clasificacion" hide-details dense solo outlined readonly flat />
                        </v-col>
                        <v-col cols="12">
                            <v-label>Observación</v-label>
                            <v-textarea v-model="item.observacion" hide-details dense solo outlined readonly flat />
                        </v-col>
                    </v-row>
                </DashCard>
            </v-col>
            <!-- solicitante -->
            <v-col cols="12" sm="6" md="6" lg="6">
                <DashCard title="Áreas">
                    <v-label>Area SIGRO</v-label>
                    <v-radio-group readonly hide-details v-model="item.area_sigro" row class="pa-0 ma-0 my-2">
                        <v-radio label="On Site" :value="1"></v-radio>
                        <v-radio label="Off Site" :value="2"></v-radio>
                    </v-radio-group>
                    <v-row>
                        <v-col cols="12">
                            <v-label>Empresa</v-label>
                            <v-text-field hide-details dense solo outlined flat readonly v-model="empresa.nombre" prepend-inner-icon="assignment_ind" />
                        </v-col>
                        <v-col cols="12" md="6">
                            <v-label>Area</v-label>
                            <v-text-field hide-details dense solo outlined flat readonly v-model="area.nombre" prepend-inner-icon="room" />
                        </v-col>
                        <v-col cols="12" md="6">
                            <v-label>Gerencia</v-label>
                            <v-text-field hide-details dense solo outlined flat readonly v-model="gerencia.nombre" prepend-inner-icon="room" />
                        </v-col>
                    </v-row>
                </DashCard>
            </v-col>
            <v-col cols="12" sm="6" md="6" lg="6">
                <DashCard title="Responsables">
                    <v-col cols="12">
                        <v-label>Encargado/a</v-label>
                        <PersonaListItem v-model="persona_autor" />
                    </v-col>
                    <v-col cols="12">
                        <v-label>Jefe del encargado/a</v-label>
                        <PersonaListItem v-model="jefe_autor" />
                    </v-col>
                </DashCard>
            </v-col>
            <!--- tareas -->
            <v-col cols="12">
                <DashCard title="Tareas" body-class="pa-0">
                    <GestorTareas 
                        v-model="item.tareas" 
                        model-type="incidentes" 
                        :read-only="!$parent.$parent.get_permission('edit')"
                        @tarea-change="reload" />
                </DashCard>
            </v-col>
            <!--- georeferencia -->
            <v-col cols="12">
                <BoxGeoreferencia ref="geo" v-model="item.georeferencia" readonly />
            </v-col>
        </v-row>
    </div>
</template>

<script>
import MapCard from '@/dash/components/MapCard'
import DashCard from '@/shared/components/DashCard'
import GestorTareas from '@/dash/components/GestorTareas'
import PersonaListItem from '@/dash/components/PersonaListItem'
import BoxGeoreferencia from '@/dash/form_boxes/box_georeferencia'
import AccessMessage from '@/dash/components/AccessMessage'

import { axiosHelper, color_estados, messages } from '@/helpers'
import moment from 'moment'

export default {
    components: {
        DashCard,
        MapCard,
        PersonaListItem,
        BoxGeoreferencia,
        GestorTareas,
        AccessMessage
    },
    data: () => ({
        versiones: [],
        item: {},
        title: {
            text: "Incidente",
            icon: "inbox"
        },
        tarea_header: 
        [
            { text: "Tarea", value: "texto", sortable: false },
            { text: "Estado", value: "estado", sortable: false },
            { text: "Vencimiento", value: "vencimiento", sortable: false },
            { text: "Cierre", value: "cierre", sortable: false },
            { text: "Responsable Cierre", value: "persona_cierre", sortable: false },
            { text: "Acciones", value: "acciones", sortable: false, align: 'center' }
        ]
    }),
    computed: {
        area () {
            return this.item.area || {};
        },
        empresa () {
            return this.item.empresa || {};
        },
        gerencia () {
            return this.item.gerencia || {};
        },
        persona_cierre () {
            return this.item.persona_cierre || {};
        },
        persona_autor () {
            return this.item.persona_autor || {};
        },
        jefe_autor () {
            return this.item.jefe_autor || {};
        }
    },
    methods: {
        reload() {
            this.$parent.$parent.reloadComponent();
        }
    },
    mounted() {
        this.$emit('title', this.title);

        if(!this.$parent.$parent.get_permission('read')) return;

        axiosHelper.get('incidentes/' + this.$route.params.id)
            .then(response => {
                this.item = response.data;
                this.title.text = "Incidente " + this.item.id + ": " + (this.item.cod_incidente || "Sin ID");
                if(this.item.georeferencia) {
                    this.$refs.geomapa.add_geojson(this.item.georeferencia.data);
                }
            });
    }
}
</script>
<template>
    <div>
        <v-data-table :headers="header" :items="value" :mobile-breakpoint="0" :items-per-page="-1" hide-default-footer>
            <template v-slot:item.vencimiento="{ item }">{{ item.vencimiento | moment("L") }}</template>
            <template v-slot:item.cierre="{ item }">{{ item.cierre | moment("L") }}</template>
            <template v-slot:item.estado="{ item }">
                <v-chip dark :color="item.estado | color_estado('tarea')">
                    {{ item.estado | estado('tarea') }}
                </v-chip>
            </template> 
            <template v-slot:item.estado_tiempo="{ item }">
                <v-chip dark :color="item.estado_tiempo | color_estado('tiempo')">
                    {{ item.estado_tiempo | estado('tiempo') }}
                </v-chip>
            </template>
            <template v-slot:item.persona_autor="{ item }">
                <v-chip v-if="!!item.persona_autor" class="ma-1" dark color="indigo">
                    <v-icon left>account_circle</v-icon>
                    <span>{{ item.persona_autor.first_name }} {{ item.persona_autor.last_name }}</span>
                </v-chip>
                <span v-else>No asignado</span>
            </template>
            <template v-slot:item.persona_cierre="{ item }">
                <v-chip v-if="!!item.persona_cierre" class="ma-1" dark color="indigo">
                    <v-icon left>account_circle</v-icon>
                    <span>{{ item.persona_cierre.first_name }} {{ item.persona_cierre.last_name }}</span>
                </v-chip>
            </template>
            <template v-slot:item.acciones="{ item }">
                    <template v-if="editMode">
                        <v-btn v-if="!editMode" text fab small color="primary" depressed @click="eliminar_tarea(item)">
                            <v-icon>close</v-icon>
                        </v-btn>
                        <v-btn text fab small color="primary" depressed @click="editar_tarea(item)">
                            <v-icon>edit</v-icon>
                        </v-btn>
                    </template>
                    <template v-else>
                        <v-menu offset-y v-if="!readOnly">
                            <template v-slot:activator="{ on }">
                                <v-btn :disabled="item.estado != 1" text fab small color="primary" depressed v-on="on">
                                    <v-icon>more_vert</v-icon>
                                </v-btn>
                            </template>
                            <v-list :dense="$vuetify.breakpoint.mdAndUp">
                                <v-list-item @click="ask_cambiar_estado(item, 2)">
                                    <v-list-item-icon>
                                        <v-icon>close</v-icon>
                                    </v-list-item-icon>
                                    <v-list-item-title>Cerrar tarea</v-list-item-title>
                                </v-list-item>
                                <v-list-item @click="ask_cambiar_estado(item, 0)">
                                    <v-list-item-icon>
                                        <v-icon>block</v-icon>
                                    </v-list-item-icon>
                                    <v-list-item-title>Invalidar tarea</v-list-item-title>
                                </v-list-item>
                            </v-list>
                        </v-menu>
                    </template>
            </template>
        </v-data-table>
        <DialogTarea ref="editor_dialog" @success="add_tarea" :model-type="modelType" :personas="personas" />
        <CommonDialog ref="error_dialog"></CommonDialog>
        <CommonDialog ref="ask_dialog" @onClose="cambiar_estado" />
    </div>
</template>

<script>
import CommonDialog from '@/shared/components/CommonDialog'
import DialogTarea from '@/dash/dialogs/DialogTarea'
import { messages, axiosHelper } from '@/helpers'

export default {
    components: {
        CommonDialog,
        DialogTarea
    },
    props: { 
        value: {
            type: Array,
            default: () => ([])
        },
        modelType: {
            type: String,
            default: "undefined"
        },
        editMode: {
            type: Boolean,
            default: false
        },
        readOnly: {
            type: Boolean,
            default: false
        },
        personas: {
            type: Array,
            default: () => ([])
        }
    },
    computed: {
        header() {
            var header = [];
            header.push({ text: "Tarea", value: "texto", sortable: false });

            if(this.modelType == 'permisos') {
                header.push({ text: "Responsable", value: "persona_autor", sortable: false });
            }

            header.push({ text: "Estado", value: "estado", sortable: false });

            if(!this.editMode) {
                header.push({ text: "Cumplimiento", value: "estado_tiempo", sortable: false });
            }

            header.push({ text: "Vencimiento", value: "vencimiento", sortable: false });

            if(!this.editMode) {
                header.push({ text: "Cierre", value: "cierre", sortable: false });
                header.push({ text: "Responsable Cierre", value: "persona_cierre", sortable: false });
            }
            header.push({ text: "Acciones", value: "acciones", sortable: false, align: 'center' });

            return header;
        }
    },
    methods: {
        add_tarea(item, edit, index) {
            if(!edit) {
                this.value.push(item);
            } else {
                this.$set(this.value, index, item);
                this.$forceUpdate();
            }
        },
        nueva_tarea(item) {
            this.$refs.editor_dialog.show(false);
        },
        editar_tarea(item) {
            var index = this.value.indexOf(item);
            this.$refs.editor_dialog.show(true, item, index);
        },
        eliminar_tarea(item) {
            if(this.permiso.tareas.length < 2) 
            {
                this.$refs.error_dialog.showDialog(messages.titles["error_generico"], messages.texts["error_permiso_tarea_one"], "ok")
                return;
            }

            this.value.splice(this.value.indexOf(item), 1);
        },
        ask_cambiar_estado(tarea, estado) {
            this.$refs.ask_dialog.showDialog(
                messages.titles["question_tarea_estado"], 
                messages.texts[estado == 2 ? "question_tarea_cerrar" : "question_tarea_invalidar"], 
                "yes_no",
                { tarea, estado });
        },
        cambiar_estado(response, target) {
            if(response != 'yes') return;

            let patch_data = { 
                tarea_id: target.tarea.id, 
                estado: target.estado
            };
            
            axiosHelper.patch(this.modelType + '/' + this.$route.params.id + '/', patch_data)
                .then(response => this.$emit('tarea-change'))
                .catch(error => {
                    this.$refs.ask_dialog.showDialog(
                        messages.titles["error_generico"], 
                        messages.texts["error_tarea_cambio_estado"], 
                        "ok");
                });
        }
    }
}
</script>